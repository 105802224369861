import { usersSessions, workspaces } from "@/api";
import AvatarWithFallback from "@/components/ui/avatar-with-fallback";
import { buttonVariants } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useCurrentWorkspace from "@/lib/hooks/use-current-workspace";
import { cn, indexBy } from "@/lib/utils";
import { WorkspaceIndex } from "@/types/serializers";
import { Link, router } from "@moraki/inertia-react";
import { t } from "i18next";
import { capitalize, truncate } from "lodash";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import Icon from "@/components/ui/icon";
import usePageProp from "@/lib/hooks/use-page-prop";
import { Check } from "lucide-react";
import { useEffect, useRef, useState } from "react";

const Workspaces = () => {
  const currentWorkspace = useCurrentWorkspace();
  const userWorkspaces = usePageProp<WorkspaceIndex[]>("user_workspaces");
  const workspacesBySlug = indexBy(userWorkspaces, "slug");
  const searchRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const filterFn = (value: string, search: string) =>
    workspacesBySlug[value].name
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase())
      ? 1
      : 0;

  useEffect(() => {
    setTimeout(() => searchRef.current?.focus(), 300);
  }, []);

  return (
    <DropdownMenuItem
      asChild
      onFocusCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
        searchRef.current?.focus();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="focus:!bg-white"
    >
      <Command className="min-w-full p-0" loop filter={filterFn}>
        <CommandInput
          ref={searchRef}
          placeholder={t("select.search")}
          className="search-input"
          onClick={(e) => e.stopPropagation()}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              const selected = optionsRef.current?.querySelector(
                "[data-selected]",
              ) as HTMLDivElement;

              selected.click();
            }
          }}
        />
        <CommandEmpty>{t("select.noResults")}</CommandEmpty>
        <CommandGroup
          className={cn("sm:max-h-48 sm:overflow-auto min-w-full")}
          ref={optionsRef}
        >
          {userWorkspaces?.map((workspace) => (
            <CommandItem
              key={`search-with-select-option-key-${workspace.slug}`}
              value={workspace.slug}
              onSelect={(value) => {
                if (value === currentWorkspace.slug) return;

                setLoading(true);
                router.get(
                  workspaces.show.path(workspacesBySlug[value]),
                  undefined,
                  {
                    onFinish: () => setLoading(false),
                  },
                );
              }}
              className="group"
            >
              <Check
                className={cn(
                  "mr-2 h-4 w-4",
                  currentWorkspace.slug == workspace.slug
                    ? "opacity-100"
                    : "opacity-0",
                  loading && "group-data-[selected=true]:hidden",
                )}
              />
              {loading && (
                <Icon
                  name="loader-circle"
                  className="hidden animate-spin group-data-[selected=true]:block mr-2 h-4 w-4"
                />
              )}
              {workspace.name}
            </CommandItem>
          ))}
        </CommandGroup>
      </Command>
    </DropdownMenuItem>
  );
};

export default function WorkspaceDropdown({
  onlyWorkspaces,
}: {
  onlyWorkspaces?: boolean;
}) {
  const workspace = useCurrentWorkspace();
  const userWorkspaces = usePageProp<WorkspaceIndex[]>("user_workspaces");

  const otherWorkspaces = userWorkspaces.filter(
    (c) => c.slug !== workspace.slug,
  );
  const hasOtherWorkspaces = otherWorkspaces.length > 0;

  if (onlyWorkspaces && !hasOtherWorkspaces) return null;

  return (
    <div data-testid="workspace-dropdown">
      <DropdownMenu>
        <DropdownMenuTrigger
          data-qa="workspace-link"
          className={cn(
            buttonVariants({ variant: "link", size: "link" }),
            "hover:no-underline gap-2 pl-2",
          )}
          role="trigger"
        >
          <AvatarWithFallback avatarable={workspace} size="lg" />
          <h4 className="font-semibold">
            {truncate(capitalize(workspace.name), { length: 20 })}
          </h4>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          side="bottom"
          className="min-w-[263px] ml-8"
          role="menu"
        >
          {!onlyWorkspaces && (
            <>
              <DropdownMenuGroup>
                <DropdownMenuItem asChild>
                  <Link href={workspaces.show.path(workspace)}>
                    {t("dashboard.title")}
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
            </>
          )}

          {onlyWorkspaces ? (
            <DropdownMenuGroup>
              <Workspaces />
            </DropdownMenuGroup>
          ) : (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                {t("workspaces.switch")}
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <Workspaces />
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}

          {!onlyWorkspaces && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem asChild>
                  <Link href={workspaces.new.path()}>
                    {t("workspaces.new")}
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => router.delete(usersSessions.destroy.path())}
                >
                  {capitalize(t("logout").toLowerCase())}
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
