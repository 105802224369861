import {
  adminWorkspaces,
  autopilot,
  companies,
  companiesAssistant,
  companiesAssistantSessions,
  companiesAssistantTasks,
  companiesAutopilot,
  companiesAutopilotRuns,
  companiesDocuments,
  companiesFinancials,
  companiesInvestments,
  companiesValuations,
  consultant,
  firm,
  firmsCompanies,
  firmsDocuments,
  firmsTaskBlueprints,
  firmsTemplates,
  operatingPartnerTasks,
  strategies,
  workspaces,
} from "@/api";
import { IconProps } from "@/components/ui/icon";
import PingIndicator from "@/components/ui/ping-indicator";
import useCurrentWorkspace from "@/lib/hooks/use-current-workspace";
import useIsStrategy from "@/lib/hooks/use-is-strategy";
import usePageProp from "@/lib/hooks/use-page-prop";
import { AdminPending } from "@/types/serializers";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface Tab {
  name: ReactNode;
  href: string;
  isActive: boolean;
  icon: IconProps["name"];
}

const RedDot = () => <PingIndicator color="red" className="mt-1.5 ml-1.5" />;

export const getDashboardTabs = (): Tab[] => {
  const workspace = useCurrentWorkspace();
  const { t } = useTranslation();
  const isStrategy = useIsStrategy();

  return [
    {
      name: t("dashboard.title"),
      href: workspaces.show.path(workspace),
      isActive: workspaces.show.isCurrent(),
      icon: "chart-spline",
    },
    {
      name: t("firm.title"),
      href: firm.show.path(workspace),
      isActive: [
        firm.show,
        firmsDocuments.index,
        firmsCompanies.index,
        firmsTemplates.index,
        firmsTaskBlueprints.index,
        companies.edit,
        companies.new,
        companies.show,
        companiesInvestments.index,
        companiesInvestments.new,
        companiesValuations.index,
        companiesValuations.new,
        companiesFinancials.index,
        companiesFinancials.new,
        companiesDocuments.index,
      ].some((x) => x.isCurrent()),
      icon: "briefcase",
    },
    null, // separator
    {
      name: t("strategy.title"),
      href: strategies.index.path(workspace),
      isActive:
        [strategies.index, strategies.show].some((x) => x.isCurrent()) ||
        isStrategy,
      icon: "graduation-cap",
    },
    {
      name: t("consultant.title"),
      href: consultant.index.path(workspace),
      isActive:
        [
          consultant.index,
          consultant.show,
          companiesAssistant.gateway,
          companiesAssistant.show,
          companiesAssistantSessions.show,
          companiesAssistantTasks.new,
        ].some((x) => x.isCurrent()) && !isStrategy,
      icon: "pi",
    },
    {
      name: t("analyst.title"),
      href: autopilot.index.path(workspace),
      isActive: [
        autopilot.index,
        autopilot.runs,
        companiesAutopilot.new,
        companiesAutopilot.show,
        companiesAutopilotRuns.index,
        companiesAutopilotRuns.show,
      ].some((x) => x.isCurrent()),
      icon: "glasses",
    },
    {
      name: t("operatingPartner.title"),
      href: operatingPartnerTasks.index.path(workspace),
      isActive: [
        operatingPartnerTasks.index,
        operatingPartnerTasks.edit,
        operatingPartnerTasks.new,
      ].some((x) => x.isCurrent()),
      icon: "user-round-cog",
    },
  ] as Tab[];
};

export const getAdminTabs = (): Tab[] => {
  const pending = usePageProp<AdminPending>("pending");

  return [
    {
      name: <>Workspaces {pending.workspaces && <RedDot />}</>,
      href: adminWorkspaces.index.path(),
      isActive: [
        adminWorkspaces.index.isCurrent(),
        adminWorkspaces.show.isCurrent(),
      ].some((x) => x),
      icon: "briefcase",
    },
  ] as Tab[];
};
