// JsFromRoutes CacheKey fc303517ab33625dbb1d085d0eb16e64
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/assistant/tasks/new/:task_blueprint_id'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/assistant/tasks'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/assistant/tasks/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/companies/:company_id/assistant/tasks/:id'),
}
