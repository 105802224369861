// JsFromRoutes CacheKey 17317b968a65b964171eeffdefdafa08
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  runs: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/autopilot/runs'),
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/autopilot'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/autopilot/:id'),
}
