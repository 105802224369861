// JsFromRoutes CacheKey 2ab96e096de0970f9f369b1dd5f712c9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/operating_partner_tasks'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/operating_partner_tasks'),
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/operating_partner_tasks/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/operating_partner_tasks/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/operating_partner_tasks/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/operating_partner_tasks/:id'),
}
