export default function LogoIcon({ className }: { className?: string }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="612.000000pt"
      height="612.000000pt"
      viewBox="0 0 612.000000 612.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,612.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1760 4654 c0 -100 -5 -228 -11 -285 -23 -224 34 -491 143 -677 51
-87 180 -213 258 -250 102 -49 180 -66 305 -66 201 0 374 62 529 190 58 48
165 175 166 197 0 5 -30 -18 -67 -51 -270 -237 -543 -305 -824 -202 -154 56
-248 143 -321 297 -62 131 -98 309 -98 478 0 116 3 115 147 -26 140 -137 185
-172 283 -216 83 -38 122 -79 156 -162 15 -36 42 -100 61 -141 31 -67 36 -73
47 -57 7 9 21 56 30 105 10 48 28 110 42 139 27 56 61 82 236 176 253 137 403
312 457 532 27 113 33 158 18 152 -7 -2 -95 -62 -196 -132 l-184 -128 -41 12
c-155 46 -193 53 -326 53 -89 1 -166 -5 -225 -16 -99 -19 -195 -43 -203 -50
-2 -3 83 -5 189 -5 261 0 442 -24 567 -76 19 -8 40 -15 46 -15 7 0 49 25 95
55 46 30 87 55 92 55 12 0 1 -45 -22 -91 -28 -56 -106 -118 -252 -199 -229
-128 -264 -157 -311 -264 -14 -31 -27 -56 -29 -56 -3 0 -23 29 -45 64 -46 73
-81 101 -236 189 -67 38 -130 82 -162 115 -60 60 -140 189 -213 344 -28 58
-62 125 -76 149 l-25 44 0 -181z"
        />
        <path
          d="M3282 4234 c-1 -109 -19 -224 -48 -306 -8 -23 -11 -45 -7 -48 5 -4
52 -39 106 -79 560 -416 874 -1085 923 -1969 l7 -123 -49 20 c-218 86 -479
206 -641 293 -303 165 -483 296 -683 497 -153 153 -231 257 -322 424 l-49 92
6 -49 c19 -143 82 -304 172 -439 101 -152 311 -336 540 -475 87 -52 346 -183
392 -197 16 -5 20 -10 12 -18 -7 -7 -86 -11 -233 -11 -177 0 -240 4 -303 18
-226 50 -464 171 -572 290 -206 228 -275 628 -184 1072 10 49 17 90 16 92 -8
9 -71 -124 -100 -211 -106 -317 -80 -652 69 -891 158 -253 472 -419 880 -467
139 -16 384 -7 518 20 l106 21 109 -44 c59 -24 171 -71 248 -103 77 -33 149
-63 161 -67 20 -6 20 -4 17 131 -22 810 -214 1402 -589 1820 -100 110 -314
280 -414 328 l-35 17 0 151 c0 117 -4 168 -19 222 -29 109 -34 107 -34 -11z"
        />
        <path
          d="M2151 4016 c-53 -30 -64 -89 -26 -134 58 -70 160 -31 157 60 -2 60
-79 104 -131 74z"
        />
        <path
          d="M2729 3971 c-39 -39 -40 -92 -3 -126 58 -54 154 -15 154 62 0 46 -45
93 -90 93 -22 0 -41 -9 -61 -29z"
        />
        <path
          d="M2874 3237 c4 -79 33 -144 100 -229 63 -79 165 -164 410 -340 417
-299 526 -406 693 -674 11 -18 12 -18 12 2 1 37 -39 178 -69 243 -85 181 -222
313 -565 541 -327 218 -446 323 -573 506 -11 16 -12 9 -8 -49z"
        />
        <path
          d="M2630 1774 c-83 -27 -104 -39 -131 -77 -42 -57 -37 -129 10 -164 13
-9 18 -6 28 17 38 91 218 113 485 60 162 -33 326 -48 374 -35 26 7 22 9 -39
22 -38 7 -159 45 -270 83 -111 39 -234 77 -274 85 -70 15 -153 18 -183 9z"
        />
        <path
          d="M2732 1521 c-65 -22 -89 -44 -114 -99 -23 -51 -18 -90 17 -122 l24
-23 10 24 c16 41 54 68 121 83 98 23 197 19 365 -15 158 -32 377 -50 388 -32
4 6 -13 13 -41 17 -26 3 -153 42 -282 86 -306 103 -386 116 -488 81z"
        />
      </g>
    </svg>
  );
}
