// JsFromRoutes CacheKey f6b1cdd39817c87842c2f44b7df5b546
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/documents'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/documents'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/companies/:company_id/documents/:id'),
}
